<template>
  <div class="home">
    <img alt="RED DEV logo" src="../assets/logo_full.svg" class="full_logo">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card">
            <img src="../assets/G_key.svg" class="card-img-top moodcomposer_logo" alt="MOOD COMPOSER logo">
            <div class="card-body">
              <p class="card-text">Composez à l’aide de l’intelligence artificielle.</p>
              <a href="https://moodcomposer.com" target="_blank" rel="noopener" class="btn btn-danger">MOOD COMPOSER</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <img src="../assets/pamphlet.svg" class="card-img-top moodcomposer_logo" alt="MOOD COMPOSER logo">
            <div class="card-body">
              <p class="card-text">Transformez vos textes en audio grâce à l’intelligence artificielle.</p>
              <a href="https://pamphlet.red-dev.fr" target="_blank" rel="noopener" class="btn btn-danger">PAMPHLET</a>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  
  }
}
</script>

<style lang="scss">
.full_logo {
  height: 12rem;
  width: 12rem;
}
.moodcomposer_logo {
  height: 8rem;
  width: 8rem;
}
.home {
  margin: 2rem;
}
.card {
  margin-top: 2rem;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
</style>